import React, { PureComponent } from 'react';
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Button, Table } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  shortAddr,
  nativeBalance,
  lessOneXLMAalert,
  getAuth,
  needDeauthAll,
  checkConnectProvider, signConnectProvider, setLocalWallets, getStellarFee
} from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Multisig from './Settings/Multisig';
import RequiredSigners from '../../Auth/components/RequiredSigners';
import swal from 'sweetalert';
import { GLOBE } from '../../../../modules/globeVars/index';
import axios from 'axios';
import slugify from '@sindresorhus/slugify';


const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      type={type}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}



class AccountSettings extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      loader: false,
      settings: window.location.hash === '#settings' ? true : false,
      value_copy: '',
      copied: false,
      name_wallet: props.account.title,
      wallets: this.props.my_wallets,
      signer: [],
      show_secret: false,
      secret: '',
      need_add_signers: false,
      transaction: false,
      federation_name: false,
      change_fed_name: false,
      status_name: true,
    };

    this.addSigners = this.addSigners.bind(this);
  }


  fee = getStellarFee()


  componentDidMount() {
    this.getWallets();

    if (this.props.federation_name) {
      const federation_name = this.props.federation_name.split('*')[0];
      this.props.change('federation_name', federation_name)
      this.setState({ federation_name })
    }
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleSettings = () => {
    this.setState({
      settings: !this.state.settings,
    })
  }


  getWallets() {
    var wallets = this.state.wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === this.props.account.pk;
      });

      this.setState({
        // wallets: wallets,
        signer: signer,
      })
    }
  }



  addSigners(value) {

    this.setState({
      transaction: value,
      need_add_signers: false,
    })

    if (value) {

      switch(this.state.action_name) {
        case 'inflation':
          this.setInflationTx();
          break
        case 'home_domain':
          this.setHomeDomainTx();
          break
        case 'account_merge':
          this.setAccountMergeTx();
          break
        case 'lock_account':
          this.setlockAccount();
          break

        default:
          break
      }
    }
  }


  setNameWallet(values) {

    let name_wallet = values.name_wallet;
    if (!name_wallet) {
      ToastStore.warning('Please enter your wallet name');
    }
    else {

      var wallets = this.state.wallets;
      wallets[this.props.account.id].title = name_wallet;

      setLocalWallets(wallets)

      this.setState({
        name_wallet: name_wallet,
      })
      ToastStore.success('Wallet Name Updated!');
    }
  }


  setInflation(values) {

    let destination = values.inflation;

    if (!destination) {
      ToastStore.warning('Enter Destination Account');
    }
    else if (!StellarSdk.StrKey.isValidEd25519PublicKey(destination)) {
      ToastStore.error('Invalid destination address');
    }
    else {

      this.setState({
        inflationDest: destination,
      });

      this.setInflationTx();
    }
  }


  setInflationTx() {

    this.setState({ loader: true });

    const address = this.props.account.pk

    Server.loadAccount(address)
      .then((sourceAccount) => {

        const submitTx = (num) => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.setOptions({
              inflationDest: this.state.inflationDest
            }))
            .setTimeout(100)
            .build();

          if (checkConnectProvider(address)) {

            return signConnectProvider(this.state.transaction, address).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {

            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

            const master_weight = sourceAccount.signers.reverse()[0].weight;

            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
              this.setState({
                need_add_signers: true,
                btn_name: 'Set Inflation',
                btn_icon: '',
                action_name: 'inflation',
                type_tx: 'med',
              })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {

        this.setState({ loader: false })

        if (result) {
          ToastStore.success('Destination account set successfully!');

          this.setState({ transaction: false });
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({
          loader: false,
          transaction: false,
        })
        ToastStore.error('Transaction error');
    });
  }


  setHomeDomain(values) {

    let home_domain = values.home_domain;

    if (!home_domain) {
      ToastStore.warning('Enter Home Domain');
    }
    else {

      this.setState({
        home_domain: home_domain,
      })

      swal({
        title: "Confirm",
        text: "Are you sure you want to update your Home Domain right now?",
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then(confirm => {
        if (confirm) {

          getAuth()
            .then((value) => {
              if (value === 'accept') {

                this.setHomeDomainTx();
              }
              else if (value === 'need_deauth_all') {
                needDeauthAll();
              }
            });
        }
      });
    }
  }


  setHomeDomainTx() {

    this.setState({ loader: true })
    const address = this.props.account.pk

    Server.loadAccount(address)
      .then((sourceAccount) => {

        const submitTx = () => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.setOptions({
              homeDomain: this.state.home_domain,
            }))
            .setTimeout(100)
            .build();

          if (checkConnectProvider(address)) {

            return signConnectProvider(this.state.transaction, address).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {

            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

            var master_weight = sourceAccount.signers.reverse()[0].weight;
            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

              this.setState({
                need_add_signers: true,
                btn_name: 'Set Home Domain',
                btn_icon: 'globe',
                action_name: 'home_domain',
                type_tx: 'med',
              })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }

      })
      .then((result) => {

        this.setState({ loader: false })

        if (result) {

          this.setState({ transaction: false });

          ToastStore.success('Home Domain successfully installed!');
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({
          loader: false,
          transaction: false,
        })
        ToastStore.error('Transaction error');
    });
  }



  setAccountMerge(values) {

    if (!values.target_account) {
      ToastStore.warning('Enter Target Account');
    }
    else if (!StellarSdk.StrKey.isValidEd25519PublicKey(values.target_account)) {
      ToastStore.error('Invalid target address');
    }
    else if (values.target_account === this.props.account.pk) {
      ToastStore.warning('The source and destination address match');
    }
    else {

      this.setState({
        merge_dest: values.target_account
      })

      getAuth()
        .then((value) => {
          if (value === 'accept') {
            acceptSendTX();
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });

      const acceptSendTX = () => {

        swal({
          title: "Attention!",
          text: "Do you really want to merge this Account right now?",
          icon: "warning",
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        .then(confirm => {
          if (confirm) {

            let subentry_count = this.props.account_data.subentry_count;
            let native_balance = nativeBalance(this.props.account_data);

            if (native_balance > 1) {
              if (subentry_count > 0) {
                swal({
                  title: `Subentry count (${subentry_count})`,
                  text: "The source account has trustlines/offers. Before merging, they must be removed.",
                  icon: "info"
                });
              }
              else {
                this.setAccountMergeTx();
              }
            }
            else {
              lessOneXLMAalert(this.props.account_data);
            }
          }
        });
      }
    }
  }


  setAccountMergeTx() {

    this.setState({ loader: true })
    const address = this.props.account.pk

    Server.loadAccount(address)
      .then((sourceAccount) => {

        const submitTx = (num) => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.accountMerge({
              destination: this.state.merge_dest,
            }))
            .setTimeout(100)
            .build();

          if (checkConnectProvider(address)) {

            return signConnectProvider(this.state.transaction, address).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {

            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

            const master_weight = sourceAccount.signers.reverse()[0].weight;
            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.high_threshold > master_weight) {
              this.setState({
                need_add_signers: true,
                btn_name: 'Account Merge',
                btn_icon: 'caret-square-o-right',
                action_name: 'account_merge',
                type_tx: 'high',
              })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {

        if (result) {
          const wallets = this.state.wallets
          wallets.splice(this.props.account.id, 1);

          setLocalWallets(wallets)

          this.setState({
            loader: false,
            transaction: false,
          })

          swal({
            title: "Account is merged!",
            text: "This Account has been successfully merged and deleted.",
            icon: "success",
            confirm: true,
          })
          .then(confirm => {
            if (confirm) {
              window.location.reload();
            }
          });
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({
          loader: false,
          transaction: false,
        })
        ToastStore.error('Transaction error');
    });
  }



  getSecretKey(values) {

    if (values.checkbox_1 && values.checkbox_2 && values.checkbox_3 && values.checkbox_4) {

      getAuth()
        .then((value) => {
          if (value === 'accept') {
            acceptSendTX();
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });

      const acceptSendTX = () => {

        this.setState({
          show_secret: true,
          secret: window.atob(this.state.signer[0].sk),
        })
      }
    }
    else {
      ToastStore.warning('All conditions must be accepted!');
    }
  }


  deauthAddress() {

    swal({
      title: "Attention!",
      text: "Are you sure you want to deauthorize an account from local memory right now?",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then(confirm => {
      if (confirm) {

        const wallets = this.state.wallets

        wallets.splice(this.props.account.id, 1);

        setLocalWallets(wallets)

        swal({
          title: "Deleted!",
          text: "Wallet successfully deauthorized.",
          icon: "success",
          confirm: true,
        })
        .then(confirm => {
          if (confirm) {
            window.location.reload();
          }
        });
      }
    });
  }



  lockAccount() {

    swal({
      title: "Attention!",
      text: "After this operation, the account will never be able to send transactions to the network. Are you sure you want to lock this account FOREVER right now?",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then(confirm => {
      if (confirm) {

        getAuth()
          .then((value) => {
            if (value === 'accept') {

              this.setlockAccount();
            }
            else if (value === 'need_deauth_all') {
              needDeauthAll();
            }
          });
      }
    });
  }


  setlockAccount() {

    this.setState({ loader: true })
    const address = this.props.account.pk

    Server.loadAccount(address)
      .then((sourceAccount) => {

        const submitTx = () => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.setOptions({
                masterWeight: 0,
                lowThreshold: 0,
                medThreshold: 0,
                highThreshold: 0,
            }))
            .setTimeout(100)
            .build();

          if (checkConnectProvider(address)) {

            return signConnectProvider(this.state.transaction, address).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {

            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

            var master_weight = sourceAccount.signers.reverse()[0].weight;
            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

              this.setState({
                need_add_signers: true,
                btn_name: 'Lock Account',
                btn_icon: 'lock',
                action_name: 'lock_account',
                type_tx: 'high',
              })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {

        if (result) {
          this.setState({
            loader: false,
            transaction: false,
          })

          swal({
            title: "Account Locked",
            text: "This Account is locked out of outgoing transactions forever.",
            icon: "success",
            confirm: true,
          })
          .then(confirm => {
            if (confirm) {
              window.location.reload();
            }
          });
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({
          loader: false,
          transaction: false,
        })

        ToastStore.error('Transaction error...');
    });
  }



  changeFederationName(value) {
    // console.log('changeFederationName value: ', value);

    let name = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        name = name + value[key];
      }
    }

    if (name) {

      this.setState({
        federation_name: slugify(name),
        change_fed_name: true,
      })

      const formData = new FormData();
      formData.append('name', name);

      axios.post(GLOBE.API_URL+`/check_federation`, formData)
        .then(result => {
          console.log('check_federation result: ', result)

          if (result.data === 'free') {
            this.setState({ status_name: true })
          } else {
            this.setState({ status_name: false })
          }
        })
        .catch((error) => {
          console.log('error: ', error)
      });
    }
    else {
      this.setState({
        federation_name: false,
        change_fed_name: false,
      })
    }
  }


  setFederationName(values) {
    // console.log('setFederationName values: ', values);

    if (values.federation_name && this.state.status_name) {

      const federation_name = slugify(values.federation_name);

      const formData = new FormData();
      formData.append('name', federation_name);
      formData.append('pk', this.props.account.pk);

      axios.post(GLOBE.API_URL+`/set_federation`, formData)
        .then(result => {
          console.log('set_federation result: ', result)

          if (result.data === 'success' || result.data === 'update') {
            swal({
              title: "Federation address set!",
              text: `Your Federation Address ${values.federation_name}*scopuly.com has been successfully set.`,
              icon: "success",
              closeOnClickOutside: false,
              buttons: {
                confirm: true,
              },
            })
            .then((confirm) => {
              if (confirm) {
                //...
              }
            })
          }
          else {
            //...
          }
        })
        .catch((error) => {
          console.log('error: ', error)
      });
    }
    else {
      ToastStore.warning('Required fill in all fields');
    }
  }



  renderSettingsTabs() {

    const { handleSubmit, wallet } = this.props;
    const provider = wallet.length ? wallet[0].provider : ''

    return (
      <div>
        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={this.props.account_data}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             action_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={this.state.type_tx} />
          : null
        }

        <div className="tabs tabs--bordered-bottom">
          <div className="">

            { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : '' }

            <Nav tabs style={{textTransform: 'none'}} >
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  <b>Name</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  <b>Inflation</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  <b>Home Domain</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => {
                    this.toggle('4');
                  }}
                >
                  <b>Account Merge</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => {
                    this.toggle('5');
                  }}
                >
                  <b>MultiSig</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '6' })}
                  onClick={() => {
                    this.toggle('6');
                  }}
                >
                  <b>Secret Key</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '7' })}
                  onClick={() => {
                    this.toggle('7');
                  }}
                >
                  <b><i className="fa fa-lock"></i> Lock Account</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '8' })}
                  onClick={() => {
                    this.toggle('8');
                  }}
                >
                  <b>Logout</b>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>

              <TabPane tabId="1">
                <Row>
                  <Col lg={6}>
                    <div>
                      <br/>
                      <div className="card__title">
                        <h5 className="bold-text">Local account name</h5>
                        <h5 className="subhead">Set any convenient wallet name for you. Stored only locally.</h5>
                      </div>
                      <form className="material-form" onSubmit={handleSubmit(this.setNameWallet.bind(this))}>
                        <div>
                          <Field
                            name="name_wallet"
                            component={renderTextField}
                            label={this.state.name_wallet}
                          />
                        </div>
                        <br/>
                        <Button color="primary" type="submit">Save Name</Button>
                      </form>
                      <br/><br/>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <br/>
                      <div className="card__title">
                        <h5 className="bold-text">Federation Name</h5>
                        <h5 className="subhead">Your Federation address {' '}
                          {
                            this.state.federation_name ?
                              <span><b className={`text-${this.state.status_name ? 'success' : 'danger'}`}>{this.state.federation_name}</b><b>*scopuly.com</b></span>
                            : ' Indefined'
                          }</h5>
                      </div>

                      <form className="material-form fed-form" onSubmit={handleSubmit(this.setFederationName.bind(this))}>
                        <div className="form__form-group-field">
                            <Field
                              style={{width: '70%'}}
                              name="federation_name"
                              component={renderTextField}
                              type="text"
                              label={'Federation Name'}
                              onChange={(value) => this.changeFederationName(value)}
                            />
                            <div className="form__form-group-icon" style={{marginTop: 10}}>*scopuly.com</div>
                          </div>
                          {
                            !this.state.status_name ?
                              <div style={{marginTop: '-20px'}}>
                                <small className="text-danger">This name already exists</small>
                              </div>
                            : null
                          }

                        <br/>
                        <Button color="primary"
                                type="submit"
                                disabled={this.state.change_fed_name && this.state.status_name ? false : true}
                              >Save Federation Name</Button>
                      </form>
                    </div>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="2">
                <Col lg={6}>
                  <div>
                    <br/>
                    <div className="card__title">
                      <h5 className="bold-text">Set Inflation</h5>
                      <h5 className="subhead">Enter the voting address for the weekly distribution of the Lumens XLM inflation pool.</h5>
                    </div>

                    <form className="material-form" onSubmit={handleSubmit(this.setInflation.bind(this))}>
                      <div>
                        <Field
                          name="inflation"
                          component={renderTextField}
                          label={this.props.account_data.inflation_destination ? shortAddr(this.props.account_data.inflation_destination, 12) : 'Enter Address Destination'}
                        />
                      </div>

                      <br/>
                      <Button color="primary" type="submit">Set Inflation</Button>
                    </form>
                  </div>
                </Col>
              </TabPane>

              <TabPane tabId="3">
                <Col lg={6}>
                  <div>
                    <br/>
                    <div className="card__title">
                      <h5 className="bold-text">Set Home Domain</h5>
                      <h5 className="subhead">Home Domain for this Account</h5>
                    </div>

                    <form className="material-form" onSubmit={handleSubmit(this.setHomeDomain.bind(this))}>
                      <div>
                        <Field
                          name="home_domain"
                          component={renderTextField}
                          label={this.props.account_data.home_domain ? this.props.account_data.home_domain : 'Enter Home Domain'}
                        />
                      </div>

                      <br/>
                      <Button color="primary" type="submit">Set Home Domain</Button>
                    </form>
                  </div>
                </Col>
              </TabPane>

              <TabPane tabId="4">
                <Col lg={6}>
                  <div>
                    <br/>
                    <div className="card__title">
                      <h5 className="bold-text">Account Merge</h5>
                      <h5 className="subhead">Transfers the native balance (the amount of XLM an account holds) to another account and removes the source account from the ledger.</h5>
                    </div>

                    <form className="material-form" onSubmit={handleSubmit(this.setAccountMerge.bind(this))}>
                      <div>
                        <Field
                          name="target_account"
                          component={renderTextField}
                          label="Target Account"
                        />
                      </div>

                      <br/>
                      <Button color="primary" type="submit">Account Merge</Button>
                    </form>
                  </div>
                </Col>
              </TabPane>

              <TabPane tabId="5">
                <Multisig account_data={this.props.account_data} />
              </TabPane>

              <TabPane tabId="6">
                <Col lg={6}>

                  {
                    provider === 'scopuly' || provider === 'freighter' || provider === 'albedo' || provider === 'rabet' ?
                      <div><br/>
                        <p>This account is connected through the extension {' '}
                          <b>{provider.charAt(0).toUpperCase() + provider.slice(1)}</b> and Scopuly cannot access the Private Key.</p><br/>
                      </div>
                    :
                      <div>
                        <br/>
                        <div className="card__title">
                          <h5 className="bold-text">Show Secret Key</h5>
                          <h5 className="subhead">Confirmation required</h5>
                        </div>

                        <p><b>Attention!</b> You are about to access the Secret Key of your account. This means that by inserting
                          this key in any other application (not in Scopuly) - you agree that after this action you
                          will not have questions to the Scopuly project, since disclosing the key means that you
                          can authorize your account in any other applications except Scopuly and make transactions
                          from there in Stellar.</p>

                        <br/>

                        <form className="material-form" onSubmit={handleSubmit(this.getSecretKey.bind(this))}>
                          <div>
                            <Field
                              name="checkbox_1"
                              component={renderCheckBoxField}
                              className="colored-click"
                              label="After disclosing the Secret Key of this account, Scopuly is not responsible for the status and security of this account."
                            />
                          </div>

                          <div>
                            <Field
                              name="checkbox_2"
                              component={renderCheckBoxField}
                              className="colored-click"
                              label="After disclosing the Secret Key, Scopuly makes a note in its databases that the Secret Key has been disclosed from this address and that all responsibility for the state of the account passes entirely to the user of this account."
                            />
                          </div>

                          <div>
                            <Field
                              name="checkbox_3"
                              component={renderCheckBoxField}
                              className="colored-click"
                              label="You confirm that after disclosing the Secret Key from your account, you will not have questions to Scopuly, either now or in the future, regarding the condition / integrity / preservation of this account."
                            />
                          </div>

                          <div>
                            <Field
                              name="checkbox_4"
                              component={renderCheckBoxField}
                              className="colored-click"
                              label="I have read everything, fully understand and agree with the above conditions."
                            />
                          </div>

                          <br/>
                          <Button color="primary" type="submit">Get Secret Key</Button>
                        </form>

                        {
                          this.state.show_secret ?
                            <div>
                              <br/>
                              <b className="text-secondary">{shortAddr(this.state.secret, 10)}</b>
                              <br/><br/>

                              <CopyToClipboard text={this.state.secret}
                                               onCopy={() => {}}>
                                <Button color="warning" size="sm" onClick={() => ToastStore.success('Copied')}>Copy Full Secret Key</Button>
                              </CopyToClipboard>

                            </div>
                            : null
                        }

                      </div>
                  }
                </Col>
              </TabPane>

              <TabPane tabId="7">
                <Col lg={6}>
                  <div>
                    <br/>
                    <div className="card__title">
                      <h5 className="bold-text">Lock Account</h5>
                      <h5 className="subhead"><i className="fa fa-exclamation-triangle"></i>{' '}
                      This option locks the account forever. This means that the account can no longer send transactions.</h5>
                    </div>

                    <Button color="secondary" onClick={this.lockAccount.bind(this)}><i className="fa fa-lock"></i> Lock Account</Button>
                  </div>
                </Col>
              </TabPane>

              <TabPane tabId="8">
                <Col lg={6}>
                  <div>
                    <br/>
                    <div className="card__title">
                      <h5 className="bold-text">Disconnect Account</h5>
                      <h5 className="subhead">Delete the wallet from the local memory of the browser.
                        Make sure that you have saved the address, seed phrase and password. At any time
                        you can connect this wallet to Scopuly again.</h5>
                    </div>

                    <Button color="secondary" onClick={this.deauthAddress.bind(this)}>Logout</Button>
                  </div>
                </Col>
              </TabPane>

            </TabContent>

          </div>
        </div>
      </div>
    )
  }



  render() {

    const ref_link = this.props.partner ? `${window.location.origin}/ref/${this.props.partner.code}` : false

    return (
      <Col xs={12} md={12} lg={12} xl={12}>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Card>
          <CardBody style={{border: '1px solid #54e5bbb5'}}>
            <div className="card__title">
              <h5 className="bold-text">Manage <font className="text-success">my Account</font>
                <span className="float-right">
                  <Button color="primary" size="sm" onClick={this.toggleSettings}><i className="fa fa-cog"></i> Settings</Button>
                </span>
              </h5>
              <h5 className="subhead">{this.props.account.title}</h5>
              {
                ref_link ?
                  <h5 className="subhead">Refcode: {' '}

                    <Link to={ref_link}>
                      <b>{this.props.partner.code}</b>
                    </Link> {' '}

                    <CopyToClipboard text={ref_link} onCopy={() => {}}>
                      <i className="fa fa-clone"
                         style={{cursor: 'pointer'}}
                         onClick={() => ToastStore.success('Copied!')}></i>
                    </CopyToClipboard>
                  </h5>
                : null
              }
            </div>

            {
              this.state.settings ? this.renderSettingsTabs() : null
            }

          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default reduxForm({
  form: 'floating_labels_form, defaults_controls_colored_click', // a unique identifier for this form
})(translate('common')(AccountSettings));
