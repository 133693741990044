import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, ButtonToolbar, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Footer from '../../Layout/footer/Footer';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { get_loc_wallets, shortAddr, numFormat, } from '../../../modules/index';
import swal from 'sweetalert';
import renderSelectField from '../../../shared/components/form/Select';




const renderTextField = ({
  input, label, type, multiline, meta: { touched, error }, children, select, inputType, 
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      select={select}
      type={type}
      multiline={multiline}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}

// renderTextField.propTypes = {
//   input: PropTypes.shape().isRequired,
//   label: PropTypes.string.isRequired,
//   meta: PropTypes.shape({
//     touched: PropTypes.bool,
//     error: PropTypes.string,
//   }),
//   select: PropTypes.bool,
//   children: PropTypes.arrayOf(PropTypes.element),
// };

// renderTextField.defaultProps = {
//   meta: null,
//   select: false,
//   type: false, 
//   multiline: false, 
//   children: [],
//   // sighers_show: false,
// };




class Xdr extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      signers: false,
      offset: 2,
      transaction: false,
      keys: [],
      select_signer: false,
      sign_tx: false,
      account: false,
      xdr: false,
    };
  }

  componentDidMount() {  

    const input = document.querySelectorAll('.Select-input input');
    input[0].setAttribute('disabled', true)
  }

  makeSignersList(account) {
    let signers = [];

    if (this.state.wallets) {

      this.state.wallets.map((item) => {

        account.signers.map((item_signer) => {
          if (item_signer.key === item.pk) {

            signers.push({
              value: item.pk,
              label: `${shortAddr(item.pk, 4)} / ${item.title}`
            })
          }
        })
      })
    }

    this.setState({
      signers: signers,
    })
  }


  submitForm(values) {
    // console.log('submitForm values: ', values)

    // if (values.target.value !== '') {
    //   ToastStore.warning('Enter the XDR code');
    // }
    // else {

    //   alert(1)

    //   // this.setState({
    //   //   sighers_show: true,
    //   // })
    // }
  }



  changeSigner(value) {
    // console.log('changeSigner value: ', value)

    this.setState({
      select_signer: value.value,
    })
  }


  changeXdr(value) {
    const input = document.querySelectorAll('.Select-input input');

    if (value.target.value !== '') {
      this.setState({
        offset: 0,
      })

      input[0].removeAttribute('disabled');

      this.decodeXDR(value.target.value);
    }
    else {
      this.setState({
        offset: 2
      })

      input[0].setAttribute('disabled', true)
    }
  }


  decodeXDR(xdr) {
    console.log('xdr: ', xdr)
    const passPhrase = "Public Global Stellar Network ; September 2015"
    // const transaction = new StellarSdk.Transaction(value, passPhrase);

    const transaction = new StellarSdk.Transaction(xdr, passPhrase);
    console.log('transaction: ', transaction)
    console.log('signatureBase: ', transaction.signatureBase())

    const signatureBuffer = '' // Buffer.from(transaction.signatures[0]._attributes.signature, 'base64');
    console.log('signatureBuffer: ', signatureBuffer)


    for (let n in transaction.signatures) {
      const hint = transaction.signatures[n].hint();
      console.log('hint: ', hint)
    }

    // const result = await str.signTransaction("44'/148'/0'", transaction.signatureBase());
    // const signature = result.signature;
    // console.log('signature: ', signature)

    // const keyPair = StellarSdk.Keypair.fromPublicKey(publicKey);
    // const hint = keyPair.signatureHint();
    // const decorated = new StellarSdk.xdr.DecoratedSignature({hint: hint, signature: signature});

    // const signatures = (transaction.signatures() || []).slice();
    // console.log('signatures: ', signatures)

    this.setState({ transaction });

    this.getAccount(transaction.source);
  }


  signTransaction() {

    const wallets = get_loc_wallets();
    const signer = wallets.filter((item) => {
      return item.pk === this.state.select_signer;
    });

    this.setState({ loader: true })

    Server.loadAccount(this.state.select_signer)
      .then((sourceAccount) => {
        this.setState({ loader: false })

        this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
        return true;
      })
      .then((result) => {
        this.setState({ loader: false })

        if (result) {

          this.setState({
            sign_tx: true,
          });
          // ToastStore.success('Signature successfully added!');
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({ loader: false })
        ToastStore.error('Sign error');
    });
  }


  getAccount(account_id) {

    this.setState({ loader: true });

    Server.loadAccount(account_id)
      .then((account) => {
        // console.log('account: ', account);

        this.setState({ 
          loader: false,
          account: account,
        });

        this.makeSignersList(account);
      })
      .catch((err) => {
        console.log('error: ', err);
        this.setState({ loader: false });
    })
  }


  getXDR() {

    const xdr = this.state.transaction.toEnvelope().toXDR('base64');
    console.log('xdr: ', xdr);

    this.setState({
      xdr: xdr,
    })
  }


  sendTransaction() {

    this.setState({ loader: true })

    Server.loadAccount(this.state.select_signer)
      .then((sourceAccount) => {
        this.setState({ loader: false })

        return Server.submitTransaction(this.state.transaction);
      })
      .then((result) => {
        // console.log('result: ', result)
        this.setState({ loader: false })

        if (result.hash) {

          this.setState({
            sign_tx: true,
          });

          ToastStore.success('Signature successfully added!');
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({ loader: false })
        ToastStore.error('Sign error');
    });
  }



  render() {

    const {reset, handleSubmit} = this.props;
    const { transaction, select_signer, offset, signers, loader, xdr, account } = this.state

    // console.log('state: ', this.state)

    return (

      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 

        <Row>
          <Col md={{size: 8, offset: offset}}>
            <h3 className="page-title">Sign XDR</h3>
            <h4 className="subhead" style={{marginBottom: '18px'}}>
              Sign an XDR transaction with one of the authorized Addresses.
            </h4>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 8, offset: offset }}>
            <Card>
              <CardBody>

                { loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                <div className="card__title">
                  <h5 className="bold-text">XDR Code</h5>
                  <h5 className="subhead">Paste XDR transaction to display details.</h5>
                </div>
                <br/>
                <form className="form form--horizontal" 
                      onSubmit={handleSubmit(this.submitForm.bind(this))}>


                  <div className="form__form-group">
                    <span className="form__form-group-label">XDR code</span>
                    <div className="form__form-group-field">
                      <Field
                        name="xdr"
                        component="textarea"
                        type="text"
                        placeholder="Paste XDR code"
                        onChange={(value) => {
                          this.changeXdr(value)
                        }}
                      />
                    </div>
                  </div>


                  <div className="form__form-group">
                    <span className="form__form-group-label">Select Signer</span>
                    <div className="form__form-group-field">
                      <Field
                        name="signer"
                        component={renderSelectField}
                        placeholder="Select Signer Address"
                        options={ signers ? signers : null }
                        onChange={(value) => {
                          this.changeSigner(value)
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <br/>
                    <ButtonToolbar className="form__button-toolbar float-right">
                      <Button color="warning" 
                              disabled={select_signer ? false : true}
                              onClick={() => this.signTransaction()}><i className="fa fa-key"></i> Sign Transaction</Button>
                      <Button color="primary" 
                              disabled={select_signer && this.state.sign_tx ? false : true}
                              onClick={() => this.getXDR()}><i className="fa fa-code"></i> Get XDR</Button>
                      <Button color="success" 
                              disabled={select_signer && this.state.sign_tx ? false : true}
                              onClick={() => this.sendTransaction()}><i className="fa fa-paper-plane-o"></i> Send to Network</Button>
                    </ButtonToolbar>
                  </div>
                </form>

                {
                  xdr ?
                    <div className="text-center">
                      <hr/>
                      <h3>XDR:</h3>
                      <div style={{wordWrap: 'break-word'}}>{xdr}</div>
                      <br/>
                      <CopyToClipboard text={xdr}
                        onCopy={() => {}}>
                        <Button color="secondary" onClick={() => ToastStore.success('Copied!')}>
                          <i className="fa fa-files-o"></i> Copy XDR
                        </Button>
                      </CopyToClipboard>
                    </div>
                  : null
                }

              </CardBody>
            </Card>
          </Col>

          {
            offset === 0 && transaction ?
              <Col md={{ size:4 }}> 
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Details</h5>
                      <h5 className="subhead">Decoding and detailing XDR data.</h5>
                    </div>
                    <p>Source Account:{' '}
                      <b className="text-info">
                        <Link to={`/account/${transaction.source}`}>{shortAddr(transaction.source, 4)}</Link>
                      </b>
                    </p>
                    <div>
                      <small>Signers for Source Account: 
                        {
                          account ?
                            account.signers.map((item, index) => (
                              <div style={{marginLeft: '30px'}}>
                                <small>{index+1}. <Link key={index} to={`/account/${item.key}`} className={`text-${transaction.source === item.key ? 'warning' : null}`}><b>{shortAddr(item.key, 4)}</b></Link> weight: <b>{item.weight}</b></small>
                              </div>
                            ))
                          : null
                        }
                      </small>
                    </div>
                    <div>
                      <small>Thresholds for Source Account: 
                        <div style={{marginLeft: '30px'}}>
                          <small>
                            Low: <b className="text-info">{account ? account.thresholds.low_threshold : '...'}</b>,{' '}
                            Medium: <b className="text-info">{account ? account.thresholds.med_threshold : '...'}</b>,{' '}
                            High: <b className="text-info">{account ? account.thresholds.high_threshold : '...'}</b>{' '}
                          </small>
                        </div>
                      </small>
                    </div>
                    <hr/>
                    <p>Signed Transaction</p>
                    <p>Signatures: <b className="text-info">{transaction.signatures.length}</b></p>
                    <p>Sequence: <b className="text-info">{transaction.sequence}</b></p>
                    <p>Fee: <b className="text-info">{transaction.fee}</b></p>
                    <p>Operations:</p>
                    <div style={{margin: '0 0 30px 20px'}}>
                      {
                        transaction.operations.map((item, index) => {
                          // console.log('item: ', item)
                          // console.log('code: ', item.line)



                          return (
                            <div key={index}>
                              {
                                Object.keys(item).map((item_key, index) => {
                                  console.log('item_key: ', item_key)
                                  console.log('item[item_key]: ', item[item_key])

                                  if (item[item_key]) {
                                    return (
                                      <div key={index}>
                                        { item_key === 'signer' ?
                                              <div>
                                                <small>{item_key}:{' '}
                                                  <b className="text-info">{item_key === 'signer' ? <Link to={`/account/${item[item_key].ed25519PublicKey}`}>{shortAddr(item[item_key].ed25519PublicKey, 4)}</Link> : item[item_key]}</b>{' '}
                                                  {item_key === 'signer' && item[item_key].weight === 0 ? <span className="text-warning"><i className="fa fa-exclamation-triangle"></i> <span>Deleting this Signer</span></span> : null}
                                                </small>
                                                <div>
                                                  <small>weight: <b className="text-info">{item[item_key].weight}</b></small>
                                                </div>
                                              </div>
                                            : null
                                        }
                                      </div>
                                    )
                                  }
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            : null
          }
        </Row>


        <Footer />

      </Container>
    );
  }
}


Xdr.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'xdr_form', 
})(translate('common')(Xdr));

