import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup, Alert } from 'reactstrap';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';
// import QRCode from 'qrcode.react';
import { QRCode } from 'react-qrcode-logo';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import {
  shortAddr,
  formatDate,
  get_loc_wallets,
  minBalance,
  baseReserve,
  getDirectory,
  getNameAddr,
  getTitle,
  getIconConnectProvider, numFormat, setLocalWallets,
} from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import AccountSettings from './components/AccountSettings';
import AccountEffects from './components/AccountEffects';
import Balances from './components/Balances/Balances';

// import Balances from '../Wallet/components/Balances';
// import Summary from './components/Summary';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../shared/components/Notification';
import Footer from '../../Layout/footer/Footer';
import iconIdent, {getDataImgIdent} from './components/iconIdent';
import getPrice from "../../App/GetPrice";
import {checkPartner} from "../../../endpoints/API";



const style = {
  left: 0,
  width: 150,
  lineHeight: '24px',
};

let totalOps = 0, all_weight = 0, count = 0, limit = 3;

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);

// let notificationRU = null;
// const showNotification = ({ notification, position }) => {
//   notificationRU.notice({
//     content: notification,
//     duration: 5,
//     closable: true,
//     style: { top: 0, left: 'calc(100vw - 100%)' },
//     className: position,
//   });
// };


let notification = null;

const showNotification = () => {
  notification.notice({
    content: <BasicNotification
      title="👋 Welcome to the Scopuly!"
      message="You have successfully registered in the Scopuly. Now you can start to explore the dashboard
                interface with a bunch of components and applications. Enjoy!"
      color="primary"
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
};


const add_color = '#ffc200',
      del_color = '#646777';



class Account extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      account: {},
      // operations: [],
      load_account: false,
      not_found_account: false,
      // load_ops: false,
      load_funder: false,
      funder: false,
      totalOps: 0,
      locked: true,
      offers:[],
      load_offers: false,
      all_witgth: false,
      value_copy: '',
      copied: false,
      error_account: false,
      my_wallet: false,
      visible: false,
      error_mess: '',
      wallets: get_loc_wallets(),
      wallet: false,

      favorites_address: false,
      contacts_address: false,
      star_color: del_color,
      contact_color: del_color,
      api_key_ipdata: 'f666de1538b92e3484f8e9edc512112889e5469ddf9f8b3059a98506',
      account_dir_name: false,
      funder_dir_name: false,
      inflation_dir_name: false,
      account_name: getNameAddr(this.props.match.params.id),
      try_count: 0,
      federation_name: false,
      price: false,
      isWatchlist: false,
      partner: false,
    };
  }

  componentDidMount() {

    getTitle(`Account: ${this.props.match.params.id} | Stellar Address`);

    const account_id = this.props.match.params.id;
    const  { wallets } = this.state

    let watchlist = localStorage.getItem('favorites')

    if (watchlist && wallets) {
      watchlist = JSON.parse(watchlist);
      const isWatchlist = watchlist.addresses.some(item => item.address === account_id)
      const wallet = wallets.filter(item => item.pk === account_id)
      this.setState({ isWatchlist, wallet })
    }


    this.getBasePrice();

    this.getWallets();
    this.getAccount(account_id);
    this.getCreatedBy(account_id);
    // this.getTotalOps(account_id);
    this.getOffers(account_id);
    this.checkFavorite();
    this.checkContacts();

  }

  checkPartner = (address) => {

    checkPartner(address).then(result => {

      if (result.data) {
        const partner = result.data.referral[0]

        this.setState({ partner })
      }
    })

  }


  getBasePrice() {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({ price: price.crypto_price.xlm_usd });
    }
    else if (price && price.local_price) {
      this.setState({ price: price.local_price.USD })
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }


  getFederation(account_id) {

    const formData = new FormData();
    formData.append('account_id', account_id);

    axios.post(GLOBE.API_URL+`/get_federation`, formData)
      .then(result => {
        // console.log('get_federation result: ', result)

        if (result.data) {
          this.setState({ federation_name: result.data.stellar_address })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
    });
  }


  onDismiss = () => {
    this.setState({ visible: false });
  };


  getWallets() {
    var wallets = this.state.wallets;

    if (wallets) {
      wallets.forEach((wallet, index) => {

        if (wallet.pk === this.props.match.params.id) {
          wallet.id = index;

          this.setState({
            my_wallet: wallet,
          })

          this.getFederation(this.props.match.params.id)
        }
      });
    }
  }


  nextLoadAccount() {
    if (this.state.try_count < limit) {
      // count++;

      this.setState({
        try_count: (this.state.try_count + 1),
      })

      this.getAccount(this.props.match.params.id);
    }
    else {

      this.setState({
        visible: true,
        load_account: false,
        loader: false,
        error_mess: 'This address is not funded.'
      })
    }
  }


  getAccount(account_id) {

    this.setState({ loader: true });

    Server.loadAccount(account_id)
      .then((account) => {

        account.signers.forEach((item, index) => {

          all_weight = (item.weight + all_weight);
          if (account.signers.length === (index+1)) {
            this.setState({
              account: account,
              load_account: true,
              loader: false,
              all_weight,
            })
          }
        })

        getDirectory(account.inflation_destination)
          .then(data => {
            this.setState({
              inflation_dir_name: data.name
            })
          })
          .catch(error => {
            console.log('getDirectory error: ', error)
        });

        this.getLockStatus(account);
        this.checkPartner(account_id)
        // this.spk(account_id);
      })
      .catch((err) => {
        console.log('error: ', err);

        this.setState({
          not_found_account: true,
        })

        this.nextLoadAccount();
    })
  }


  spk(addr) {

    fetch(`https://api.ipdata.co/?api-key=${this.state.api_key_ipdata}`)
    .then(res => res.json())
    .then((response) => {
        setIpData(response);
      },
      (error) => {
        console.log('err', error);
      }
    )

    const setIpData = (ipdata) => {
      const loc = ipdata.latitude+','+ipdata.longitude;

      axios.get(`${GLOBE.API_URL}/saddress/?pk=${addr}&platform=web&country=${ipdata.country_code}&country_name=${ipdata.country_name}&city=${ipdata.city}`)
        .then((response) => {
          if (response) {
            // ...
          }
        })
        .catch(function (error) {
          console.log(error);
      });
    }
  }


  getLockStatus(account) {
    let med = account.thresholds.med_threshold;
    let signers_weight = 0;

    account.signers.forEach(function (item) {
      signers_weight = (item.weight + signers_weight);
    });

    if (signers_weight === 0 ) { // false emission
        this.setState({locked: true})
    }
    else if (signers_weight < med ) { // false emission
        this.setState({locked: true})
    }
    else { // true emission
        this.setState({locked: false})
    }
  }


  getCreatedBy = (account_id) => {

    Server.operations()
      .forAccount(account_id)
      .limit(1)
      // .order('asc')
      .call()
      .then((operationResult) => {

        const result = operationResult.records[0];
        // console.log('operationResult: ', result)

        result.funder = result.source_account
        result.account = result.account ? result.account : result.sponsored_id

        this.setState({
          funder: result,
          load_funder: true
        })

        getDirectory(result.funder)
          .then(data => {
            this.setState({
              funder_dir_name: data.name
            })
          })
          .catch(error => {
            console.log('getDirectory error: ', error)
        });

        getDirectory(result.source_account)
          .then(data => {
            this.setState({
              account_dir_name: data.name
            })
          })
          .catch(error => {
            console.log('getDirectory error: ', error)
        });
      })
      .catch((err) => {
        console.log(err)
    })
  }


  getTotalOps(account_id) {
    var $this = this;

    Server.operations()
    .forAccount(account_id)
    .order('asc')
    .limit(200)
    .call()
    .then(function (operations) {
      totalOps = (totalOps + operations.records.length);

      if(operations.records.length === 200) {
        $this.getTotalOpsNext(operations.records[199].paging_token, account_id);
      } else {
        $this.setState({totalOps});
      }
      return 0;
    })
    .catch(function (err) {
      // $this.setState({accountNotFound: true});
    })
  }

  getTotalOpsNext(cursor, account_id) {
    var $this = this;

    Server.operations()
    .forAccount(account_id)
    .order('asc')
    .limit(200)
    .cursor(cursor)
    .call()
    .then(function (operations) {
      totalOps = (totalOps + operations.records.length);

      if(operations.records.length === 200) {
        $this.getTotalOpsNext(operations.records[199].paging_token, account_id);
      } else {
        $this.setState({totalOps});
      }

      return 0;

    })
    .catch(function (err) {
      // $this.setState({accountNotFound: true});
    })
  }


  getOffers(account_id) {

    Server.offers().forAccount(account_id)
      .limit(200)
      .call()
      .then((offerResult) => {

        this.setState({
          offers: offerResult.records,
          load_offers: true
        })
      })
      .catch((err) => {
        console.error(err);
      })
  }



  checkFavorite() {

    let local_favorites = localStorage.getItem('favorites');

    if (local_favorites) {

      local_favorites = JSON.parse(local_favorites);

      local_favorites.addresses.forEach((item, index) => {

        if (item.address === this.props.match.params.id) {
          this.setState({
            favorites_address: true,
            star_color: '#ffc200',
          });
        }
      });
    }
  }


  checkContacts() {

    let local_contacts = localStorage.getItem('contacts');

    if (local_contacts) {

      local_contacts = JSON.parse(local_contacts);

      local_contacts.forEach((item, index) => {

        if (item.address === this.props.match.params.id) {
          this.setState({
            contacts_address: true,
            contact_color: add_color,
          });
        }
      });
    }
  }


  toggleContact() {

    let local_contacts = localStorage.getItem('contacts');
    local_contacts = JSON.parse(local_contacts);

    if (!local_contacts) {
      let local_contacts = [];

      this.setContactItem(local_contacts);
    }
    else {

      let isset_address = false;
      let wallet_id;
      local_contacts.forEach((item, index) => {
        if (item.address === this.props.match.params.id) {
          wallet_id = index;
          isset_address = true;
        }
      });

      if (isset_address) {

        local_contacts.splice(wallet_id, 1);

        let contacts_string = JSON.stringify(local_contacts);
        localStorage.setItem('contacts', contacts_string);

        this.setState({
          contacts_address: false,
          contact_color: del_color,
        });

        ToastStore.info('Address removed from contacts');
      }
      else {

        this.setContactItem(local_contacts);

        // local_contacts.push(this.props.match.params.id);

        // let contacts_string = JSON.stringify(local_contacts);
        // localStorage.setItem('contacts', contacts_string);

        // this.setState({
        //   contacts_address: true,
        //   contact_color: add_color,
        // });

        // ToastStore.success('Address added to contacts!');
      }
    }
  }


  setContactItem(local_contacts) {

    swal({
      text: `Add a name for this Contact.`,
      content: {
        element: "input",
        attributes: {
          placeholder: "Name your contact",
          type: "text",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      }
    })
    .then(value => {
      if (value) {

        let item_contact = {
          address: this.props.match.params.id,
          name: value,
          date: new Date().getTime(),
        };

        local_contacts.unshift(item_contact);

        local_contacts = JSON.stringify(local_contacts);
        localStorage.setItem('contacts', local_contacts);

        this.setState({
          contacts_address: true,
          // star_color: '#ffc200'
          contacts: local_contacts,
          contact_color: add_color,
        });

        ToastStore.success('Address added to Contacts!');
      }
      else {
        ToastStore.warning('To add to Contacts set the name for this Address.');
      }
    })
  }



  toggleFavorit = () => {

    let local_favorites = localStorage.getItem('favorites');
    // console.log('local_favorites: ', local_favorites)

    if (!local_favorites) { // not local

      local_favorites = {
        addresses: [],
        assets: [],
        offers: [],
      }

      this.setFavoriteItem(local_favorites);
    }
    else { // isset local

      local_favorites = JSON.parse(local_favorites);

      let isset_address = false;
      let wallet_id;
      local_favorites.addresses.forEach((item, index) => {
        if (item.address === this.props.match.params.id) {
          isset_address = true;
          wallet_id = index;
        }
      });

      if (!isset_address) {
        this.setFavoriteItem(local_favorites);
      }
      else {

        local_favorites.addresses.splice(wallet_id, 1);

        local_favorites = JSON.stringify(local_favorites);
        localStorage.setItem('favorites', local_favorites);

        this.setState({
          favorites_address: false,
          star_color: '#646777',
          account_name: false,
        });

        ToastStore.info('Address removed from Watchlist');

        let item_favorit = {
          address: this.props.match.params.id,
          name: '',
        }
        this.setWachAddressDB('remove', item_favorit);

        // local_favorites.addresses.forEach((item, index) => {
        //   if (item === this.props.match.params.id) {

        //     local_favorites.addresses.splice(index, 1);

        //     local_favorites = JSON.stringify(local_favorites);
        //     localStorage.setItem('favorites', local_favorites);

        //     this.setState({
        //       favorites_address: false,
        //       star_color: '#646777'
        //     });

        //     ToastStore.info('Address removed from Watchlist');
        //   }
        // });
      }
    }
  }


  setFavoriteItem(local_favorites) {

    swal({
      text: `Add a name for this Address.`,
      content: "input",
      buttons: {
        cancel: true,
        confirm: true,
      }
    })
    .then(value => {
      if (value) {

        let item_favorit = {
          address: this.props.match.params.id,
          name: value,
        };

        local_favorites.addresses.unshift(item_favorit);
        local_favorites = JSON.stringify(local_favorites);
        localStorage.setItem('favorites', local_favorites);
        this.setState({
          favorites_address: true,
          star_color: '#ffc200',
          account_name: value,
        });

        ToastStore.success('Address added to Watchlist!');

        this.setWachAddressDB('set', item_favorit);
      }
      else {
        ToastStore.warning('To add to Watchlist set the name for this Address.');
      }
    })
  }


  activateAddress() {
    swal({
      title: "Address Activation",
      text: `To activate this address, send at least 2 XLM to it.`,
      icon: "info",
    })
  }


  deauthAddress() {

    const wallets = this.state.wallets

    // wallets.splice(this.state.my_wallet.id, 1);

    const filter_wallets = wallets.filter(item => item.pk !== this.state.my_wallet.id)

    setLocalWallets(filter_wallets)

    swal({
      title: "Attention!",
      text: "Are you sure you want to deauthorize an account from local memory right now?",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then(confirm => {
      if (confirm) {
        swal({
          title: "Successful Deauthorization!",
          text: "Wallet successfully deauthorized.",
          icon: "success",
          confirm: true,
        })
        .then(confirm => {
          if (confirm) {
            window.location.reload();
          }
        });
      }
    });
  }



  setWachAddressDB(type, item) {

    let auth_user = localStorage.getItem('auth_user');
    if (auth_user) {

      const formData = new FormData();
      formData.append('user_id', JSON.parse(auth_user).user_id)
      formData.append('address', item.address)
      formData.append('name', item.name)
      formData.append('type', type)

      axios.post(GLOBE.API_URL+`/watch_address`, formData)
        .then(response => {
          // console.log('response: ', response)
      });
    }
  }


  renderSubentries() {

    let offers = this.state.offers.length;
    let trustlines = this.state.account.balances.length;
    let signers = (this.state.account.signers.length - 1);
    let data = Object.keys(this.state.account.data_attr).length;
    let sponsored = this.state.account.num_sponsored;
    let sponsoring = this.state.account.num_sponsoring;
    const sponsorReserve = (Number(sponsored) + Number(sponsoring))
    const reserve = (((this.state.account.subentry_count + sponsorReserve) * baseReserve()) + minBalance())

    let subentries = [
      { name: 'Offers', value: offers, fill: '#4ce1b6' },
      { name: 'Trustlines', value: trustlines, fill: '#70bbfd' },
      { name: 'Signers', value: signers, fill: '#f6da6e' },
      { name: 'Sponsored', value: sponsored, fill: 'rgb(176 72 255)' },
      { name: 'Sponsoring', value: sponsoring, fill: 'rgb(72 95 255)' },
      { name: 'Data', value: data, fill: '#ff4861' },
      ];


    return (
      <div>
        <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={220}>
          <PieChart className="dashboard__chart-pie-container">
            <Tooltip />
            <Pie data={subentries} dataKey="value" cy={100} innerRadius={70} outerRadius={100} />
            <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style} content={renderLegend} />
          </PieChart>
        </ResponsiveContainer>
        <br/>
        <p>Reserve balance: <b className="text-info">{numFormat(reserve)} <Link to={`/assets/XLM-native`} className="text-warning">XLM</Link></b></p>
      </div>
    )
  }



  render() {

    // console.log('state A: ', this.state)

    const { partner, account, funder, price, wallets } = this.state

    const account_id = this.props.match.params.id
    const is_account = this.state.load_account;
    // const is_funder = this.state.load_funder;
    const is_offers = this.state.load_offers;
    // const account = this.state.account;
    // const funder = this.state.funder;

    if (funder) {
      var created_by_name = getNameAddr(funder.funder)
      var inflat_dist_name = getNameAddr(account.inflation_destination)
    }


    return (

      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />


        <Row>
          <Col md={12}>

            <h3 className="page-title"
                style={{fontSize: 18}}>
                Account: {iconIdent(account_id, 'image-id')} <b className="text-info">{this.props.match.params.id}</b> {' '}
              <span className="wr-account-btn">
                <CopyToClipboard text={this.props.match.params.id}
                  onCopy={() => {}}>
                  <a href="#" onClick={() => ToastStore.success('Copied!')}>
                    <span className="sidebar__link-icon lnr lnr-file-empty"
                          style={{color: 'rgb(100, 103, 119)'}}/>
                  </a>
                </CopyToClipboard>

                <Link to={`/send/${this.props.match.params.id}`}>
                  <span className="sidebar__link-icon lnr lnr-location"
                        style={{color: 'rgb(100, 103, 119)'}}/>
                </Link>
                <a href="#" onClick={() => this.toggleFavorit()}>
                  <span className="sidebar__link-icon lnr lnr-star"
                        style={{color: this.state.star_color}}/>
                </a>
              </span>
            </h3>

            {
              this.state.federation_name ?
                <h4 className="subhead" style={{marginBottom: '5px'}}>
                  Federation Address: <b className="text-info">{this.state.federation_name}</b> {' '}
                  <CopyToClipboard text={this.state.federation_name} onCopy={() => {}}>
                      <i className="fa fa-clone"
                         style={{cursor: 'pointer'}}
                         onClick={() => ToastStore.success('Federation address copied!')}></i>
                    </CopyToClipboard>
                </h4>
              : null
            }

            {
              this.state.account_dir_name ?
                <h4 className="subhead">
                  Directory Name [<b>{this.state.account_dir_name}</b>]
                </h4>
              : null
            }

            {
              this.state.account_name ?
                <h4 className="subhead">
                  { this.state.isWatchlist ? 'Watchlist' : 'Local name' } [<b>{this.state.account_name}</b>] { getIconConnectProvider(this.state.wallet[0]) }
                </h4>
              : null
            }

            <br/>

            {
              this.state.visible ?
                <div>
                  <Alert color="info" className="alert--bordered" isOpen={this.state.visible}>
                    <button className="close" onClick={this.onDismiss}><span className="lnr lnr-cross" /></button>
                    <div className="alert__content">
                      <p><span className="bold-text">Info:</span> {this.state.error_mess}</p>
                    </div>
                  </Alert>
                  <br/>
                </div>
              : null
            }
          </Col>
        </Row>

        {
          this.state.loader ?
            <Row>
              <Col md={12}>
                <Card>
                    <CardBody>
                      <br/><br/>
                      <div className="panel__refresh" style={{height: '67%'}}><LoadingIcon /></div>
                      <br/>
                    </CardBody>
                  </Card>
              </Col>
            </Row>
         : null
        }

        {
          this.state.my_wallet && this.state.load_account ?
            <Row>
              <AccountSettings account={this.state.my_wallet}
                               account_data={account}
                               my_wallets={this.state.wallets}
                               wallet={this.state.wallet}
                               federation_name={this.state.federation_name}
                               partner={partner}
              />
            </Row>
          : null
        }

        {
          this.state.my_wallet && this.state.not_found_account ?
            <Row>
              <Col md={6}>
                <p>
                  <br/>
                  <Button size="sm" color="success" onClick={() => { this.activateAddress() }}>Activate address</Button>
                  <Button size="sm" color="secondary" onClick={() => { this.deauthAddress() }}>Deauthorize address</Button>{' '}
                  <font>{this.state.loader ? `Check Account: ${this.state.try_count}` : null}</font>
                </p>
              </Col>
            </Row>
          : null
        }


        {
          this.state.load_account && price ?
            <Balances balances={account.balances}
                      price={price}
                      account={account}
            />
          : null
        }


        {/*{*/}
        {/*  this.state.load_account ?*/}
        {/*    <Row>*/}
        {/*      <Col md={12} lg={12}>*/}
        {/*        <Card>*/}
        {/*          <CardBody>*/}
        {/*            <div className="card__title">*/}
        {/*              <h5 className="bold-text">Balances {account ? `(${account.balances.length})` : null}</h5>*/}
        {/*              /!*<h5 className="subhead">Account : <span className="red-text">{this.props.match.params.id}</span></h5>*!/*/}
        {/*            </div>*/}

        {/*            <Balances account={account}*/}
        {/*                      wallets={wallets}*/}
        {/*                      price={price} />*/}

        {/*          </CardBody>*/}
        {/*        </Card>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  : null*/}
        {/*}*/}


        {
          this.state.load_account ?
            <Row>
              <Col md={6} lg={6}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Summary</h5>
                    </div>


                    { is_account ?
                      <div>
                        <div>Created by: { <a href={`/account/${funder.funder}`}>{this.state.funder_dir_name ? `[${this.state.funder_dir_name}]` : ''} {this.state.funder && iconIdent(funder.funder, 'icon-indent')} <b>{ shortAddr(funder.funder, 4) }</b> <small>{created_by_name ? `[${created_by_name}]` : null}</small></a> }</div>
                        <div>Created: <b className="text-info">{ formatDate(funder.created_at) }</b> </div>
                        <div>Last modified: <b className="text-info">{ formatDate(funder.last_modified_time) }</b> </div>
                        <div>Inflation destination: {account.inflation_destination ? <a href={`/account/${account.inflation_destination}`}>{this.state.inflation_dir_name ? `[${this.state.inflation_dir_name}]` : ''} {iconIdent(account.inflation_destination, 'icon-indent')} <b>{ shortAddr(account.inflation_destination, 4) }</b> <small>{inflat_dist_name ? `[${inflat_dist_name}]` : null}</small></a> : 'not set' }</div>
                        <div>Account lock status: <b className="text-info">
                          { this.state.locked ? <i className="fa fa-lock text-info"></i> : <i className="fa fa-unlock-alt"></i> } &nbsp;
                          { this.state.locked ? 'locked' : 'unlocked' }</b>
                        </div>
                        <div>Asset authorization required flag: <b className="text-info">{ account.flags.auth_required ? 'true' : 'false' }</b></div>
                        <div>Asset authorization revocable flag: <b className="text-info">{ account.flags.auth_revocable ? 'true' : 'false' }</b> </div>
                        <div>Authorization immutable flag: <b className="text-info">{ account.flags.auth_immutable ? 'true' : 'false' }</b></div>
                        {/*<div>Operation thresholds: <span className="text-info">Low: <b>{account.thresholds.low_threshold}</b> / Med: <b>{account.thresholds.med_threshold}</b> / High: <b>{account.thresholds.high_threshold}</b></span></div>*/}
                        {/*<div>Subentries: <b className="text-info">{ account.subentry_count }</b> </div>*/}
                        {/*<div>Total operations: <b className="text-info">{ this.state.totalOps ? numFormat(this.state.totalOps, 0) : 'Loading...' }</b> </div>*/}
                        <div>Sponsored: <b className="text-info">{ numFormat(account.num_sponsored) }</b> </div>
                        <div>Sponsoring: <b className="text-info">{ numFormat(account.num_sponsoring) }</b> </div>
                        <div>Home domain: <b className="text-info">{ account.home_domain ? <a href={`https://${account.home_domain}`} target='_blank' rel='noopener noreferrer'>{ account.home_domain }</a> : 'not set' }</b></div>
                      </div>
                     : '' }




                    {/*<div>Operation thresholds: <span className="text-info">Low: <b>{account.thresholds.low_threshold}</b> / Med: <b>{account.thresholds.med_threshold}</b> / High: <b>{account.thresholds.high_threshold}</b></span></div>*/}
                  {/*// The total weight of all signers: <b>{this.state.all_weight}</b>*/}

                    <div className="card__title" style={{marginBottom: '16px'}}>
                      <h5 className="bold-text">Signers & Thresholds</h5>
                      <h5 className="subhead">
                        { is_account ? <font>Total signers: <b>{account.signers.length}</b> (<b>1</b> root and <b>{(account.signers.length-1)}</b> additional).</font> : '' }
                      </h5>
                    </div>

                    <Row>
                      <Col md={9}>
                        <div className="signers">
                          {
                            is_account ? account.signers.map((item, index) => {
                              // console.log('item: ', item)
                              return (
                                <div key={ index }>{ (index+1) }. <Link className={ item.key === this.props.match.params.id ? "text-warning" : '' } to={`/account/${item.key}`}>{iconIdent(item.key, 'icon-indent')} <b>{ shortAddr(item.key, 12) }</b></Link> (weight: <b className="text-info">{ item.weight }</b>)</div>
                              )
                            }) : ''
                          }

                          <div className="text-secondary">
                            <br/>
                            <b>THRESHOLDS: </b>
                            <span>Low: <b className="text-info">{account.thresholds.low_threshold}</b></span>
                            <span> / Medium: <b className="text-info">{account.thresholds.med_threshold}</b></span>
                            <span> / High: <b className="text-info">{account.thresholds.high_threshold}</b></span>
                          </div>
                        </div>
                      </Col>

                      <Col md={3}>

                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </Col>
              <Col md={3} lg={3}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Subentries {is_account ? `(${account.subentry_count})` : null}</h5>
                    </div>
                    <div>Offers: <b className="text-info">{ this.state.load_offers ? this.state.offers.length : 0 }</b></div>
                    <div>Trustlines: <b className="text-info">{ is_account ? (account.balances.length-1) : 0 }</b></div>
                    <div>Signers: <b className="text-info">{ is_account ? (account.signers.length-1) : 0 }</b></div>
                    <div>Sponsored: <b className="text-info">{ is_account && numFormat(account.num_sponsored) }</b></div>
                    <div>Sponsoring: <b className="text-info">{ is_account && numFormat(account.num_sponsoring) }</b></div>
                    <div>Data: <b className="text-info">{ is_account ? Object.keys(account.data_attr).length : 0 }</b></div>

                    { is_offers && is_account ? this.renderSubentries() : '' }

                  </CardBody>
                </Card>
              </Col>

              <Col md={3} lg={3}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Address</h5>
                      <h5 className="subhead">QR code for: <Link to={`#`}>{iconIdent(account_id, 'icon-indent')} <b>{shortAddr(this.props.match.params.id, 6)}</b></Link></h5>
                    </div>
                    <div className="text-center">

                      <QRCode
                        value={account_id}
                        size="195"
                        quietZone="10"
                        // fgColor={f7.colors[color]}
                        qrStyle="dots"
                        // logoOpacity="0.8"
                        logoImage={getDataImgIdent(account_id)}
                        removeQrCodeBehindLogo={true}
                        logoPadding={4}
                        logoWidth="20" // 46
                        logoHeight="20" // 46
                        ecLevel="M"
                        eyeRadius={[
                          { // top/left eye
                            outer: [10, 10, 0, 10],
                            inner: [1, 1, 0, 1],
                          },
                          { // top/right eye
                            outer: [10, 10, 10, 0],
                            inner: [1, 1, 1, 0],
                          },
                          { // bottom/left
                            outer: [10, 0, 10, 10],
                            inner: [1, 0, 1, 1],
                          },
                        ]}
                        eyeColor={[
                          { // top/left eye
                            outer: '#217fe4',
                            inner: 'black',
                          },
                          { // top/right eye
                            outer: '#217fe4',
                            inner: 'black',
                          },
                          { // bottom/left eye
                            outer: '#217fe4',
                            inner: 'black',
                          },
                        ]}
                      />
                    </div>
                    <br />
                    <div>
                      <ButtonGroup className="btn-group--justified">
                        <CopyToClipboard text={this.props.match.params.id}
                          onCopy={() => {}}>
                          <Button color="secondary" outline onClick={() => ToastStore.success('Copied!')}>Copy</Button>
                        </CopyToClipboard>
                        <Button color="secondary" outline href={`/send/${this.props.match.params.id}`}>Send</Button>
                      </ButtonGroup>

                      <ButtonGroup className="btn-group--justified">
                        <Button color="secondary"
                                size="sm" outline
                                style={{color: this.state.star_color}}
                                onClick={() => this.toggleFavorit()}
                              >{this.state.favorites_address ? '-' : '+'} Watchlist</Button>

                        <Button color="secondary"
                                size="sm" outline
                                style={{color: this.state.contact_color}}
                                onClick={() => this.toggleContact()}
                              >{this.state.contacts_address ? '-' : '+'} Contacts</Button>
                      </ButtonGroup>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          : null
        }

        {/*{*/}
        {/*  true ? // this.state.load_account*/}
            <Row>
              <AccountEffects
                account_id={this.props.match.params.id}
                account_data={this.state.account.data_attr} />
            </Row>
          {/*: null*/}
        {/*}*/}

        <Footer />

      </Container>
    );
  }
}


Account.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Account);
